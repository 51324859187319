/* You can add global styles to this file, and also import other style files */

html, body { height: 100%; }
#body { 
    margin: 0;
    font-family: "Nunito Sans", sans-serif;
    color: #212721;
    font-size: 14px;
}
:root {

    --white: #ffff;

    --light-orange: #FFEBE8;

    --dark-gray: #979797;

    --primary-orange: #E7503D;

}
/* Importing Bootstrap SCSS file. */
@import "node_modules/bootstrap/scss/bootstrap";

/* Importing Datepicker SCSS file. */
@import "node_modules/ngx-bootstrap/datepicker/bs-datepicker";

.f700 {
    font-weight: 700;
}

.text-danger {
    padding-top: 5px;
    font-size: 0.65em;
    display: block;
    line-height: 1.5;
    color: var(--primary) !important;
}

.ellipsis {
    text-overflow: ellipsis;
    overflow: hidden;
    white-space: nowrap;
}

.dropdown-menu {
    max-width: 15rem;
}

.text-black {
    color: #000;
}

.dropdown-item {
    &:focus,
    &:hover {
        background-color: inherit !important;
    }
    &:active {
        background-color: #e9ecef !important;
        color: inherit !important;
    }
}

.text-primary {
    color: var(--primary) !important;
}

.text-secondary {
    color: var(--grey1);
}

a.text-primary {
    &:hover {
        color: var(--primary) !important;
    }
}


.btn-primary {
    background-color: var(--primary);
    height: 35px;
    border: none;
    &:active {
        background-color: var(--primary) !important;
    }
    &:hover {
        background-color: var(--primary) !important;
    }
    &:disabled {
        color: #fff;
        background-color: #777C81!important;
        border-color: #777C81;
      }
}

.btn-outline-primary {
    border-color: var(--primary) !important;
    color: var(--primary) !important;
    &:hover {
        background-color: transparent;
    }
}

.btn-pill {
    border-radius: 100px;
    width: 100%;
}

.line-clamp-2 {
    display: -webkit-box;
    -webkit-line-clamp: 2;
    -webkit-box-orient: vertical;
    overflow: hidden;
}
.line-clamp-3 {
    display: -webkit-box;
    -webkit-line-clamp: 3;
    -webkit-box-orient: vertical;
    overflow: hidden;
}
.ngx-slider{
    margin: 25px 0 15px !important;
}
.ngx-slider .ngx-slider-pointer{
    background: #FFD561 !important;
    width: 24px !important;
    height: 24px !important;
    top: -10px !important;
    &::after{
        background: #FFD561 !important;
    }
}
.ngx-slider .ngx-slider-selection{
    background: var(--primary) !important;
}
.ngx-slider-bubble{
    display: none !important;
}
.form-control:not(.quant-selector){
    background-color: transparent !important;
    border: 0;
    border-bottom: 1px solid #C4C4C4;
    border-radius: 0;
    padding-left: 0;
    padding-right: 0;
    padding-bottom: 0px;
    outline: initial;
    &:focus{
        box-shadow: initial;
        border-color:  #C4C4C4;
    }
}
.form-control {
    &.is-invalid {
        background-image: none !important;
        padding-right: inherit !important;
        border-color: var(--primary) !important;
      }
}
.overlay{
    position: fixed;
    height: 100vh;
    width: 100vw;
    background: rgba(0,0,0,.25);
    bottom: 0;
    z-index: 1000;
}
ngb-datepicker{
    border: 0 !important;
    display: block !important;;
}
.ngb-dp-day, .ngb-dp-week-number, .ngb-dp-weekday {
    width: 38px !important;
    height: 38px !important;
}
[ngbDatepickerDayView] {
    width: 100% !important;
    height: 100% !important;
    line-height: 38px !important;
    border-radius: 50% !important;
    margin-top: 5px !important;
}

.modal-fullscreen {
    width: 100vw;
    max-width: none;
    height: 100%;
    margin: 0;
}

.full-screen {
    overflow-y: hidden !important;
    .modal-dialog {
        margin: 0 !important;
        .modal-content{
            border: none;
            outline: none;
        }
    }
}
.modal-open:has(.search-modal-dialog) {
    app-root {
        -webkit-filter: blur(4px);
        -moz-filter: blur(4px);
        -o-filter: blur(4px);
        -ms-filter: blur(4px);
        filter: blur(4px);
    }
}
.search-modal-dialog {
    .modal-dialog {
        margin-top: 1rem;
        .modal-content {
            border-color: var(--primary);
        }
    }
}
.checkmark {
    position: relative;
        margin-right: 8px;
        height: 15px;
        width: 15px;
        background-color: #fff;
        border: 1px solid #E7503D;
        border-radius: 2px;
        margin-bottom: 2px;
}
.checkmark + .flex{
    width: calc(100% - 23px);
}
.filter-label input {
    position: absolute;
    opacity: 0;
    height: 0;
    width: 0;
}
input[type=checkbox] {
    width: inherit !important;
}
input[type=checkbox],
input[type=radio] {
    box-sizing: border-box;
    padding: 0;
}
.filter-label input:checked~.checkmark {
    border: 1px solid #ff3e3e;
    border: 1px solid var(--primary);
}
.filter-label input:checked~.checkmark-text {
    font-weight: 700;
}
.filter-label:hover input~.checkmark {
    background-color: #fff;
}
.checkmark:after {
    content: "";
    position: absolute;
    display: none;
}
.filter-label .checkmark:after {
    transform: rotate(45deg);
    left: 5px;
    top: 2px;
    width: 4px;
    height: 8px;
    border: solid #ff3e3e;
    border: solid var(--primary);
    border-width: 0 2px 2px 0;
}
.filter-label input:checked~.checkmark:after {
    display: block;
}
.strikethrough {
    text-decoration: line-through;
}
.separator {
    margin-bottom: 12px;
    margin-top: 0px;
    flex-grow: 1;
    border-top: 1px solid #E3E5E7;
}
.skeleton-loader.circle {
    margin: 0 !important;
}
.skeleton-loader {
    border-radius: inherit !important;
    margin-bottom: 0 !important;
}

app-offer-card{
    width: 50%;
    &:nth-child(odd){
        padding-right: 7px;
    }
    &:nth-child(even){
        padding-left: 7px;
    }
}


.custom-scroller::-webkit-scrollbar{
    display: none !important;
}
.custom-scroller{
    -ms-overflow-style: none !important;
    overflow: -moz-scrollbars-none !important;
    scrollbar-width: none !important;
}

.rotate-animation {
    -webkit-animation: spin 2s linear infinite !important;
    opacity: 0.85;
    animation: spin 2s linear infinite;
}

@keyframes spin {
    0% {
        transform: rotate(0deg);
    }
    100% {
        transform: rotate(360deg);
    }
}

.dropdown {
    top: 8px !important;
}


.btn-primary:not(:disabled):not(.disabled):active, .btn-primary:not(:disabled):not(.disabled).active, .show > .btn-primary.dropdown-toggle {
    background-color:  var(--primary)!important;
}
.bs-tooltip-top .arrow::before,
.bs-tooltip-auto[x-placement^=top] .arrow::before{
    border-top-color: #fff;
}
.tooltip-inner{
    background-color: #fff;
    color: #212721;
    box-shadow: 0px 4px 4px rgb(161 179 198 / 25%);
    border-radius: 10px;
}
.details-footer {
    .footer-container {
        height: 75px !important;
    }
}