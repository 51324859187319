:root {
  --primary: #E7503D !important;
  --border: #C4C4C4 !important;
  --border2: #F0EFEE !important;
  --bg1: #FEF6F4!important;
  --grey1: #979797 !important;
}

.primary-text{
  color: var(--primary);
}